import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import emailjs, { EmailJSResponseStatus } from '@emailjs/browser';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  registerForm: FormGroup;
  subscriptions: Subscription[] = [];
  errors = '';
  user: any;
  constructor(
    private userService: UserService,
    private router: Router
  ) { }

  ngOnInit() {
    this.registerForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      phone: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required]),
      company: new FormControl('', [Validators.required]),
      noofseats: new FormControl('', [Validators.required]),
      property: new FormControl(''),
    });
  }

  submit(value: string): void {
    if (this.registerForm.invalid) {
      return;
    }
    const registerData = {
      name: this.registerForm.controls.name.value,
      phone: this.registerForm.controls.phone.value,
      email: this.registerForm.controls.email.value,
      company: this.registerForm.controls.company.value,
      noofseats: this.registerForm.controls.noofseats.value,
      property: this.registerForm.controls.property.value,
    };
    console.log(registerData);
    this.user = this.userService.register(registerData).subscribe(
      (result) => {
        if (result) {
          if (value === 'modal') {
            // this.message = true;
          } else {
            this.registerForm.reset();
            // dialogRef.afterClosed().subscribe((data) => { });
          }
        }
      },
      (e) => {
        return (this.errors = e.error);
      }
    );
  }

  public sendEmail(e: Event) {
    e.preventDefault();
    emailjs.sendForm('service_v41du9u', 'template_1cx6h3l', e.target as HTMLFormElement, 'd9zoeLUUdPiC_HuIQ')
      .then((result: EmailJSResponseStatus) => {
        console.log('Success');
        this.registerForm.reset();
        alert('Submitted Successfully!');
      }, (error) => {
        console.log('Failed');
      });
  }

  changeClient(value) {
    console.log(value);
}
}
