import { Component } from '@angular/core';

@Component({
  selector: 'app-trusted',
  templateUrl: './trusted.component.html',
  styleUrls: ['./trusted.component.scss']
})
export class TrustedComponent {

}
