import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';
import { AboutComponent } from './components/pages/about/about.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { EventComponent } from './components/pages/event/event.component';
import { EventDetailsComponent } from './components/pages/event-details/event-details.component';
import { GalleryComponent } from './components/pages/gallery/gallery.component';
import { PricingComponent } from './components/pages/pricing/pricing.component';
import { ServicesComponent } from './components/pages/services/services.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { SocialGalleryComponent } from './components/pages/social-gallery/social-gallery.component';

const routes: Routes = [
    {path: '', component: HomeOneComponent},
    {path: 'about', component: AboutComponent},
    {path: 'event', component: EventComponent},
    {path: 'event-details', component: EventDetailsComponent},
    {path: 'gallery', component: GalleryComponent},
    {path: 'social-gallery', component: SocialGalleryComponent},
    {path: 'services', component: ServicesComponent},
    {path: 'pricing', component: PricingComponent},
    {path: 'error', component: ErrorComponent},
    {path: 'contact', component: ContactComponent},
    // Here add new pages component

    {path: '**', component: ErrorComponent} // This line will remain down from the whole pages component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }
